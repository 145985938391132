import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import AddIcon from '@mui/icons-material/Add';

import { useAppDispatch, useAppSelector } from "../../redux/Hooks";
import { PracticeSliceActions, practiceSelectors } from "../../redux/reducers/practice-slice";
import { ADD_PRACTICE, UPDATE_PRACTICE } from "../../constants/api-constants";
import api from "../../api";
import { doctorStaffListResponse, practiceUpdatedPayloadProps, practicesResponseParseProps } from "../../models/practice.model";
import { ResponseModel } from "../../models/patient.model";
import { ToastAlert } from "../../components/toast-alert";
import { PRACTICE, httpStatusCode } from "../../constants/variable-constants";
import { DoctorThunkApis } from "../../redux/actions/doctor-actions";
import AddDoctorStaffModal from "./components/AddDoctorStaffModal";

import UpdatePracticeStyles from "./UpdatePractice.module.css";

const UpdatePractice = () => {
    const { practiceId } = useParams<{ practiceId: string }>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [editPractice, setEditPractice] = useState<practicesResponseParseProps | null>(null);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const practiceLists = useAppSelector(practiceSelectors.selectAll);
    const { showDoctorStaffDialog } = useAppSelector(state => state.practices || {});
    const { doctorStaffWithoutPractice } = useAppSelector(state => state.doctors! || {});

    useEffect(() => {
        dispatch(DoctorThunkApis.getAllDoctorStaffWithoutPractice(''));
    }, [dispatch, showDoctorStaffDialog]);

    const initialFormValues: practiceUpdatedPayloadProps = {
        practiceName: "",
        streetAddress: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        email: "",
        website: "",
        doctorStaffUserId: 0
    };

    useEffect(() => {
        if (practiceId) {
            const selectedPractice = practiceLists.find(practice => practice?.PracticeNameId! === +practiceId);
            setEditPractice(selectedPractice || null);
        }
    }, [practiceId, practiceLists]);

    const formik = useFormik({
        initialValues: editPractice ? {
            practiceName: editPractice.PracticeName,
            streetAddress: editPractice.StreetAddress || "",
            city: editPractice.City || "",
            state: editPractice.State || "",
            postalCode: editPractice.PostalCode || "",
            country: editPractice.Country || "",
            phoneNumber: editPractice.PhoneNumber || "",
            email: editPractice.Email || "",
            website: editPractice.Website || "",
            doctorStaffUserId: editPractice.DoctorStaffUserId || 0
        } : initialFormValues,
        validationSchema: Yup.object({
            practiceName: Yup.string().required(PRACTICE.NAME_REQUIRED),
            streetAddress: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            postalCode: Yup.string(),
            country: Yup.string(),
            phoneNumber: Yup.string().matches(/^\d+$/, PRACTICE.PHONE_NUMBER_REQUIRED).transform((value, originalValue) => originalValue.replace(/\D/g, '')),
            email: Yup.string().email(PRACTICE.INVALID_EMAIL),
            website: Yup.string().url(PRACTICE.INVALID_WEBSITE),
            doctorStaffUserId: Yup.number()
        }),
        enableReinitialize: true,
        onSubmit: async (values) => {
            await handleSubmit(values as any);
        }
    });

    const handleSubmit = async (payload: practiceUpdatedPayloadProps) => {
        setLoading(true);
        if (!payload.practiceName) {
            ToastAlert('Please provide a non-empty name of practice', 'error');
            setLoading(false);
            return;
        }
        if (!practiceId && practiceLists.some(practice => practice.PracticeName!.toLowerCase() === payload.practiceName!.toLowerCase())) {
            ToastAlert('Given name of practice already exists', 'error');
            setLoading(false);
            return;
        }
        try {
            const apiEndpoint = practiceId ? UPDATE_PRACTICE : ADD_PRACTICE;
            const response = await api.fetchAuthPostWithBody<ResponseModel<practicesResponseParseProps[]>>(apiEndpoint, practiceId ? { ...payload, practiceNameId: practiceId } : payload);
            if (response.status === httpStatusCode.SUCCESS) {
                dispatch(PracticeSliceActions.addPractices(response.data.data));
                ToastAlert(response.data.message!, 'success');
                navigate('/home/practice_lists');
            } else {
                throw new Error('Failed to add/update the practice.');
            }
        } catch (error: any) {
            ToastAlert(error?.response?.data?.message || "Something went wrong!", 'error');
        } finally {
            setLoading(false);
        }
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('phoneNumber', event.target.value);
    }

    const handleStaffChange = (event: SelectChangeEvent<number>) => {
        const id = event.target.value as number;
        formik.setFieldValue('doctorStaffUserId', id);
    };

    const handleNewStaff = () => {
        dispatch(PracticeSliceActions.patchState({ showDoctorStaffDialog: true }));
    }

    const handleClose = () => {
        dispatch(PracticeSliceActions.patchState({ showDoctorStaffDialog: false }));
    }

    return (
        <Box className={UpdatePracticeStyles.container}>
            <Box className={UpdatePracticeStyles.search_panal}>
                <Box className={UpdatePracticeStyles.panel_container}>
                    <Box className="flexCenterBetween">
                        <Box className="flexCenterStart pointer" sx={{ width: "200px" }} onClick={() => navigate('/home/practice_lists')}>
                            <img src="images/back.svg" alt="Backward_Image" className={UpdatePracticeStyles.backImg} />
                            <Typography ml={2} className={UpdatePracticeStyles.panal_header}>
                                {practiceId ? "Edit Practice Details" : "Add Practice Details"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container>
                        {[
                            { id: 'practiceName', label: 'Practice Name', placeholder: 'Enter practice name' },
                            { id: 'streetAddress', label: 'Street Address', placeholder: 'Enter street address' },
                            { id: 'city', label: 'City', placeholder: 'Enter city' },
                            { id: 'state', label: 'State', placeholder: 'Enter state' },
                            { id: 'postalCode', label: 'Postal Code', placeholder: 'Enter postal code' },
                            { id: 'country', label: 'Country', placeholder: 'Enter country' },
                            { id: 'phoneNumber', label: 'Phone Number', placeholder: 'Enter phone number' },
                            { id: 'email', label: 'Email', placeholder: 'Enter email' },
                            { id: 'website', label: 'Website', placeholder: 'Enter website' }
                        ].map(({ id, label, placeholder }, index) => (
                            <Grid item lg={4} sm={6} xs={12} p={3} key={index}>
                                <Typography className={UpdatePracticeStyles.label}>{label}</Typography>
                                {id === 'phoneNumber' ? (
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        placeholder="Enter a phone number"
                                        guide={false}
                                        id="phoneNumber"
                                        value={formik.values.phoneNumber!}
                                        onBlur={formik.handleBlur}
                                        onChange={handlePhoneChange}
                                        render={(ref, props) => (
                                            <TextField
                                                {...props}
                                                inputRef={ref}
                                                variant="standard"
                                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                                fullWidth
                                            />
                                        )}
                                    />
                                ) : (
                                    <TextField
                                        id={id}
                                        name={id}
                                        variant="standard"
                                        placeholder={placeholder}
                                        value={formik.values[id]}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched[id] && Boolean(formik.errors[id])}
                                        helperText={formik.touched[id] && formik.errors[id]}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                        ))}
                        <Grid item lg={4} sm={6} xs={12} p={3}>
                            <Typography className={UpdatePracticeStyles.label}>Doctor Staff's Name</Typography>
                            <Box className="flexCenterCenter" sx={{flexWrap: 'wrap', gap: '10px'}}>
                                <FormControl sx={{ minWidth: "100%"}} variant="standard" error={formik.touched.doctorStaffUserId && Boolean(formik.errors.doctorStaffUserId)}>
                                    <Select
                                        labelId="doctorStaffSelectLabel"
                                        id="doctorStaffSelect"
                                        value={formik.values.doctorStaffUserId!}
                                        onChange={handleStaffChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            borderRadius: "25px",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderRadius: "25px",
                                            },
                                            "& .MuiSelect-select": {
                                                padding: "7px 14px",
                                            },
                                        }}
                                    >
                                        {doctorStaffWithoutPractice?.length
                                            ? doctorStaffWithoutPractice.map((doctor: doctorStaffListResponse) => (
                                                <MenuItem key={doctor.UserId} value={doctor.UserId!}>
                                                    {doctor.FullName}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                    {formik.touched.doctorStaffUserId && formik.errors.doctorStaffUserId &&
                                        <FormHelperText>{formik.errors.doctorStaffUserId}</FormHelperText>}
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleNewStaff}
                                    sx={{
                                        padding: "6px 11px",
                                        minWidth: {md: '250px', sm: '100%', xs: '250px'},
                                        textTransform: "capitalize",
                                        borderRadius: "100px",
                                        marginLeft: "10px"
                                    }}
                                >
                                    <Typography sx={{ textTransform: "capitalize", fontWeight: "500", fontSize: {md: '1rem', sm: '0.8rem', xs: '1rem'} }}>Add New Doctor Staff</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box my={2} sx={{display: 'flex', alignItems: 'center', justifyContent: {md: 'flex-end', xs: 'center'}}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate('/home/practice_lists')}
                            sx={{ padding: "6px 11px", minWidth: "120px", textTransform: "capitalize", borderRadius: "100px" }}
                        >
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                        </Button>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={isLoading}
                            type="submit"
                            sx={{ padding: "6px 11px", minWidth: "120px", textTransform: "capitalize", borderRadius: "100px", marginLeft: "10px" }}
                        >
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Save</Typography>
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
            {showDoctorStaffDialog && <AddDoctorStaffModal open={showDoctorStaffDialog} handleClose={handleClose} />}
        </Box>
    );
};

export default UpdatePractice;