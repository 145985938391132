import React, { useCallback, useMemo } from "react";
import {
    Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton,
    MenuItem, Select, SelectChangeEvent, TextField, Typography
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { PatientSliceActions } from "../../../redux/reducers/patient-slice";
import { patientStatusResponse, referralReasonsProps } from "../../../models/patient.model";
import { doctorListsResponse } from "../../../models/practice.model";
import { USER_ROLES } from "../../../constants/variable-constants";

import PatientListStyles from "../PatientLists.module.css";

const FilterDialog = ({ closeFilter }: { closeFilter: () => void }) => {
    const dispatch = useAppDispatch();
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});
    const { referredById, referralReasons, referralReasonId, patientStatus, currentPatientStatusId, referredDate, hospitalsList, selectedHospitalId } = useAppSelector(state => state.patients! || {});
    const { doctorLists: doctorsData, allPracticeDoctorList } = useAppSelector(state => state.doctors! || {});

    // Function to remove duplicates based on UserId
    const getUniqueUsers = useCallback(() => {
        return allPracticeDoctorList?.filter((value, index, self) =>
            index === self.findIndex((t) => t.UserId === value.UserId)
        );
    }, [allPracticeDoctorList]);

    // Get list based on roleId
    const list = useMemo(() => (roleId === USER_ROLES.SUPER_ADMIN ? doctorsData : getUniqueUsers()), [roleId, doctorsData, getUniqueUsers]);

    const formik = useFormik({
        initialValues: {
            referredId: referredById || null,
            referralReasonIds: referralReasonId || [],
            referralDate: referredDate ? dayjs(referredDate).format('YYYY-MM-DD') : null,
            statusIds: currentPatientStatusId || [],
            hospitalId: selectedHospitalId || null
        },
        onSubmit: (values) => {
            dispatch(PatientSliceActions.patchState({
                currentPatientStatusId: values.statusIds,
                referralReasonId: values.referralReasonIds,
                referredById: values.referredId,
                referredOn: values.referralDate || null,
                selectedHospitalId: values.hospitalId || null
            }));
            closeFilter();
        }
    });

    const handleChange = (event: SelectChangeEvent<number>) => {
        formik.setFieldValue('referredId', event.target.value as number);
    };

    const handleHospitalChange = (event: SelectChangeEvent<number>) => {
        formik.setFieldValue('hospitalId', event.target.value as number);
    }

    const handleDateChange = (newDate: Dayjs | null) => {
        if (newDate?.isValid()) {
            const formattedDate = newDate.format('YYYY-MM-DD');
            dispatch(PatientSliceActions.patchState({ referredDate: newDate.toISOString() }));
            formik.setFieldValue('referralDate', formattedDate);
        } else {
            console.error('Invalid date');
        }
    };

    const handleReset = () => {
        formik.resetForm({
            values: {
                referredId: null,
                referralReasonIds: [],
                referralDate: null,
                statusIds: [],
                hospitalId: null
            }
        });
        dispatch(PatientSliceActions.patchState({
            currentPatientStatusId: [],
            referralReasonId: [],
            referredById: null,
            referredOn: null,
            referredDate: null,
            selectedHospitalId: null
        }));
    };

    const handleClearDate = () => {
        formik.setFieldValue('referralDate', null);
        dispatch(PatientSliceActions.patchState({ referredDate: null }));
    };

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            open={true}
            onClose={closeFilter}
            aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: 'md',
                    '@media (max-width: 480px)': {
                        width: '100%',
                        maxWidth: '100%',
                        margin: '10px'
                    }
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography className={PatientListStyles.filterTitle} pb={3}>{"Patients Filter"}</Typography>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={closeFilter}
                sx={{
                    position: 'absolute',
                    right: 2,
                    top: 2,
                }}
            >
                <CancelIcon color={'primary'} />
            </IconButton>
            <DialogContent>
                <Box component="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={PatientListStyles.label}>Doctor</Typography>
                            <FormControl sx={{ minWidth: "100%" }} variant="standard">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formik.values.referredId!}
                                    label="Status"
                                    onChange={handleChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        }
                                        , '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {list.length ? list.map((doctor: doctorListsResponse) => (
                                        <MenuItem key={doctor.UserId} value={doctor.UserId!}>{doctor.FullName}</MenuItem>
                                    )) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3} sx={{ marginTop: "7px" }}>
                            <Typography className={PatientListStyles.label}>Reason for Referral</Typography>
                            <FormControl
                                variant="standard"
                                fullWidth
                            >
                                <Autocomplete
                                    multiple
                                    id="size-small-standard-multi"
                                    size="small"
                                    options={referralReasons || [] as any}
                                    getOptionLabel={(option: referralReasonsProps) => option.ReferralReasonName!}
                                    value={referralReasons?.filter(option => formik.values.referralReasonIds.includes(option.ReferralReasonId! as any)) || []}
                                    onChange={(event, value) => {
                                        const referralReasonIds = value.map((referral: referralReasonsProps) => referral.ReferralReasonId);
                                        formik.setFieldValue('referralReasonIds', referralReasonIds);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select..."
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={PatientListStyles.label}>Hospital</Typography>
                            <FormControl sx={{ minWidth: "100%" }} variant="standard">
                                <Select
                                    labelId="hospital-select-label"
                                    id="hospital-select"
                                    name="hospitalId"
                                    label="Hospital"
                                    value={formik.values.hospitalId!}
                                    onChange={handleHospitalChange}
                                    sx={{
                                        borderRadius: '25px',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRadius: '25px'
                                        }
                                        , '& .MuiSelect-select': {
                                            padding: '7px 14px'
                                        }
                                    }}
                                >
                                    {hospitalsList?.length ? hospitalsList.map((item) => (
                                        <MenuItem key={item.HospitalId} value={item.HospitalId!}>{item.HospitalName}</MenuItem>
                                    )) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3} sx={{ marginTop: "7px" }}>
                            <Typography className={PatientListStyles.label}>Status of Patients</Typography>
                            <FormControl
                                variant="standard"
                                fullWidth
                            >
                                <Autocomplete
                                    multiple
                                    id="size-small-standard-multi"
                                    size="small"
                                    options={patientStatus || [] as any}
                                    getOptionLabel={(option: patientStatusResponse) => option.PatientStatusName!}
                                    value={patientStatus?.filter(option => formik.values.statusIds.includes(option.PatientStatusId! as any)) || []}
                                    onChange={(event, value) => {
                                        const patientStatusIds = value.map((status: patientStatusResponse) => status.PatientStatusId);
                                        formik.setFieldValue('statusIds', patientStatusIds);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select..."
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} p={3}>
                            <Typography className={PatientListStyles.label}>Date of Referral</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} className="referralPicker">
                                    <DatePicker
                                        value={formik.values.referralDate ? dayjs(formik.values.referralDate) : null}
                                        onChange={handleDateChange}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                            }
                                        }}
                                        format="YYYY-MM-DD"
                                    />
                                    {referredDate ?
                                        <Typography component={'div'} onClick={handleClearDate} color={'primary'} ml={2} sx={{ fontSize: '15px', cursor: 'pointer', textDecoration: 'underline' }}>
                                            Clear
                                        </Typography> : null}
                                </Box>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Box className="flexCenterCenter filterAction" gap={2} my={3}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            sx={{
                                padding: "6px 11px",
                                minWidth: "110px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}>
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Continue</Typography>
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={closeFilter}
                            sx={{
                                padding: "6px 11px",
                                minWidth: "110px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}>
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Cancel</Typography>
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleReset}
                            sx={{
                                padding: "6px 11px",
                                minWidth: "110px",
                                textTransform: "capitalize",
                                borderRadius: "100px"
                            }}>
                            <Typography sx={{ textTransform: "capitalize", fontWeight: "500" }}>Reset</Typography>
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default FilterDialog;