import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

import { patientDataProps } from "../../../models/patient.model";
import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { DOCTOR_STAFF_ROLE, USER_ROLES } from "../../../constants/variable-constants";
import { PatientSliceActions } from "../../../redux/reducers/patient-slice";
import { AvantTooltip } from "../../../components/tool-tip/Tooltip";
import { patientStatusColor } from "../../../constants/color-constants";

import PatientListsStyles from "../PatientLists.module.css";

dayjs.extend(utc);
interface patientTableRowProps {
    data: patientDataProps,
    patientIndex: number
}

const PatientTableRow = ({ data, patientIndex }: patientTableRowProps) => {
    const { firstName, lastName, age, gender, patientId, currentPatientStatusId, referredBy, practiceName, referredOn, hospitalId } = data! || {};

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { patientStatus, highlightPatientId, selectedPatientIds, hospitalsList } = useAppSelector(state => state.patients! || {});
    const { roleId } = useAppSelector(state => state.auth.userDetails! || {});

    const getStatusName = () => {
        const currentPatientStatus = patientStatus?.find((status) => status.PatientStatusId === currentPatientStatusId);
        return currentPatientStatus ? currentPatientStatus?.PatientStatusName : "-";
    }

    const getHospitalName = () => {
        return hospitalsList?.find(hospital => hospital.HospitalId === hospitalId)?.HospitalName || "-";
    }

    const handlePatientDeleteDialog = async () => {
        await dispatch(PatientSliceActions.patchState({ deletePatientId: patientId }));
    }

    const handleToggleSelection = () => {
        dispatch(PatientSliceActions.togglePatientSelection(patientId));
    };

    const cellClassName = patientId === highlightPatientId
        ? PatientListsStyles.patientListTableHighlighted
        : !(patientIndex % 2)
            ? PatientListsStyles.patientListTableOddCell
            : PatientListsStyles.patientListTableEvenCell;

    return (
        <Box className={PatientListsStyles.patientListTableRow} display={'contents'}>
            {[USER_ROLES.SUPER_ADMIN, USER_ROLES.USER_ADMIN, DOCTOR_STAFF_ROLE].includes(roleId!) ?
                <Box className={cellClassName}>
                    <Checkbox
                        checked={selectedPatientIds.includes(patientId)}
                        onChange={handleToggleSelection}
                    />
                </Box> : null}
            <Box className={cellClassName} sx={{width: '200px'}}>
                <Typography component={"div"} onClick={() => navigate(`/home/patient_lists/${patientId}/view`)} className={PatientListsStyles.link}>{firstName + " " + lastName}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{age}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{gender}</Typography>
            </Box>
            {[USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN, DOCTOR_STAFF_ROLE].includes(roleId!) ?
                <Box className={cellClassName}>
                    <Typography>{referredBy || "-"}</Typography>
                </Box> : null}
            <Box className={cellClassName}>
                <Typography>{dayjs.utc(referredOn).format('YYYY-MM-DD') || "-"}</Typography>
            </Box>
            {[USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN, DOCTOR_STAFF_ROLE].includes(roleId!) ?
                <Box className={cellClassName}>
                    <Typography>{getHospitalName()}</Typography>
                </Box> : null}
            {[USER_ROLES.USER_ADMIN, USER_ROLES.SUPER_ADMIN].includes(roleId!) ?
                <Box className={cellClassName}>
                    <Typography>{practiceName || "-"}</Typography>
                </Box> : null}
            <Box className={cellClassName}>
                <Typography sx={{ color: patientStatusColor[data.currentPatientStatusId!] }}>{getStatusName()}</Typography>
            </Box>
            <Box className={cellClassName}>
                <AvantTooltip title="Edit" placement="top" arrow followCursor>
                    <img src="images/edit.svg" className={PatientListsStyles.editImg} onClick={() => navigate(`/home/patient_lists/${patientId}`)} />
                </AvantTooltip>
                {Object.values(USER_ROLES).includes(roleId!) || roleId === DOCTOR_STAFF_ROLE ?
                    <AvantTooltip title="Delete" placement="top" arrow followCursor>
                        <img src="images/delete.svg" className={PatientListsStyles.editImg} onClick={handlePatientDeleteDialog} />
                    </AvantTooltip> : null}
            </Box>
        </Box>
    );
}

export default PatientTableRow;