import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/Hooks";
import { practicesResponseParseProps } from "../../../models/practice.model";
import { PracticeSliceActions } from "../../../redux/reducers/practice-slice";
import { AvantTooltip } from "../../../components/tool-tip/Tooltip";

import PracticeListsStyles from "../PracticeLists.module.css";
interface practiceTableRowProps {
    data: practicesResponseParseProps,
    userIndex: number
}

const PracticeTableRow = ({ data, userIndex }: practiceTableRowProps) => {
    const { PracticeName, Email, PhoneNumber, DoctorStaffUserId, PracticeNameId } = data! || {};

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { doctorstaffLists } = useAppSelector(state => state.doctors! || {});

    const openDeletePracticeDialog = () => {
        dispatch(PracticeSliceActions.patchState({ deletePractice: data }));
    }

    const handleViewPractice = () => {
        dispatch(PracticeSliceActions.patchState({ viewPractice: data! }));
    }

    const handleNavigate = () => {
        navigate(`/home/practice_lists/${PracticeNameId}`);
    }

    const getDoctorStaffName = () => {
        return doctorstaffLists?.find(staff => staff.UserId === DoctorStaffUserId)?.FullName || "-";
    }

    const cellClassName = !(userIndex % 2) ? PracticeListsStyles.practiceListTableOddCell : PracticeListsStyles.practiceListTableEvenCell;

    return (
        <Box className={PracticeListsStyles.practiceListTableRow} display={'contents'}>
            <Box className={cellClassName}>
                <Typography component={"div"} className={PracticeListsStyles.link} onClick={handleViewPractice}>{PracticeName}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{Email || "-"}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{PhoneNumber || "-"}</Typography>
            </Box>
            <Box className={cellClassName}>
                <Typography>{getDoctorStaffName() || "-"}</Typography>
            </Box>
            <Box className={cellClassName}>
                <AvantTooltip title="Edit" placement="top" arrow followCursor>
                    <img src="images/edit.svg" alt="Edit_Image" className={PracticeListsStyles.editImg} onClick={handleNavigate} />
                </AvantTooltip>
                <AvantTooltip title="Delete" placement="top" arrow followCursor>
                    <img src="images/delete.svg" alt="Delete_Image" className={PracticeListsStyles.editImg} onClick={openDeletePracticeDialog} />
                </AvantTooltip>
            </Box>
        </Box>
    );
}

export default PracticeTableRow